import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import  {BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _bartitle : BehaviorSubject<string | null> = new BehaviorSubject(null);
    private _isHelpCenter : BehaviorSubject<boolean | false> = new BehaviorSubject(null);
    private _isShareCode : BehaviorSubject<boolean | false> = new BehaviorSubject(null);
    private _isShareCodeTyping : BehaviorSubject<boolean | false> = new BehaviorSubject(null);
    private _isJitsi : BehaviorSubject<boolean | false> = new BehaviorSubject(null);
    private _inJitsi : BehaviorSubject<boolean | false> = new BehaviorSubject(null);
    private _roomJitsi : BehaviorSubject<string | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    /**
     * Getter and Setter for bar title
     */

    get barTitle$(): Observable<string>
    {
        return this._bartitle.asObservable();
    }

    set barTitle(title: string)
    {
        this._bartitle.next(title);
    }

    /**
     * Getter and Setter for HelpCenter
     */
    get isHelpCenter$(): Observable<boolean>
    {
        return this._isHelpCenter.asObservable();
    }

    set isHelpCenter(show: boolean)
    {
        this._isHelpCenter.next(show);
    }

    /**
     * Getter and Setter for ShareCode
     */
    get isShareCode$(): Observable<boolean>
    {
        return this._isShareCode.asObservable();
    }

    set isShareCode(show: boolean)
    {
        this._isShareCode.next(show);
    }

    /**
     * Getter and Setter for ShareCode Typing
     */
    get isShareCodeTyping$(): Observable<boolean>
    {
        return this._isShareCodeTyping.asObservable();
    }

    set isShareCodeTyping(show: boolean)
    {
        this._isShareCodeTyping.next(show);
    }

    /**
     * Getter and Setter for Jitsi
     */
    get isJitsi$(): Observable<boolean>
    {
        return this._isJitsi.asObservable();
    }

    set isJitsi(show: boolean)
    {
        this._isJitsi.next(show);
    }

    /**
     * Getter and Setter for bar title
     */
     get inJitsi$(): Observable<boolean>
     {
         return this._inJitsi.asObservable();
     }
 
     set inJitsi(inJitsi: boolean)
     {
         this._inJitsi.next(inJitsi);
     }

    /**
     * Getter and Setter for bar title
     */
     get roomJitsi$(): Observable<string>
     {
         return this._roomJitsi.asObservable();
     }
 
     set roomJitsi(room: string)
     {
         this._roomJitsi.next(room);
     }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }
}
