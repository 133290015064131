// -----------------------------------------------------------------------------------------------------
// @ AUTH CONVERGENCE
//
// Methods are derivations of the Convergence.io
// https://convergence.io
// -----------------------------------------------------------------------------------------------------

declare var require: any;
const { Convergence, RealTimeString, RealTimeObject, StringSetValueEvent } = require("@convergence/convergence");

import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, BehaviorSubject, filter, map, Observable, of, switchMap, take, takeUntil, tap, throwError, Subscription } from "rxjs";
import { AuthService } from "app/core/auth/auth.service";
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { environment } from "environments/environment";
import { Chat, Message } from "app/modules/admin/apps/chat/chat.types";

import {
  IMqttMessage,
  IMqttServiceOptions,
  MqttService,
  IPublishOptions,
} from 'ngx-mqtt';
import { IClientSubscribeOptions } from 'mqtt-browser';


//import { createClient } from 'redis';

@Injectable()
export class AuthConvergence implements OnDestroy {
  private _convergenceDomain: any = null;
  private _convergencePresence: any = null;
  private _convergenceChat: any = null;
  private _convergenceActivity: any = null;

  private _chatCurrent = {
    room: null,
    messages: null,
  };

  private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
  private _messages: BehaviorSubject<Message[]> = new BehaviorSubject(null);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private user: User;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _authService: AuthService, private _userService: UserService, private _mqttService: MqttService) {
    this.client = this._mqttService;
    //this.createConnection();
  }

  private curSubscription: Subscription | undefined;
  connection = {
    hostname: 'mqtt.coding-school.fr',
    //port: 8083,
    path: '/mqtt',
    protocol: 'ws',
   
    clean: true, // 保留会话
    connectTimeout: 4000, // 超时时间
    reconnectPeriod: 4000, // 重连时间间隔
    // 认证信息
    username: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2ODgwNTUyMDMsImV4cCI6MTcxOTU5MTIwMywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.-jUo5_xwlIg6Wzi_GUMLBuaUHlQvM26rlEB4mm0PGwGPJvFEugccySM2KPhVHHLf2_DKkUw_Kc5CCH9r1pQ9lA',
 
  }
  subscription = {
    topic: 'topic/mqttx',
    qos: 0,
  };
  publish = {
    topic: 'topic/browser',
    qos: 0,
    payload: '{ "msg": "Hello, I am browser." }',
  };
  receiveNews = '';
  qosList = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
  ];
  client: MqttService | undefined;
  isConnection = false;
  subscribeSuccess = false;

  // 创建连接
  createConnection() {
    // 连接字符串, 通过协议指定使用的连接方式
    // ws 未加密 WebSocket 连接
    // wss 加密 WebSocket 连接
    // mqtt 未加密 TCP 连接
    // mqtts 加密 TCP 连接
    // wxs 微信小程序连接
    // alis 支付宝小程序连接
    try {
      this.client?.connect(this.connection as IMqttServiceOptions)
    } catch (error) {
      console.log('mqtt.connect error', error);
      
    }
    this.client?.onConnect.subscribe(() => {
      this.isConnection = true
      console.log('Connection succeeded!');
      this.doSubscribe();
      this.doPublish();
    });
    this.client?.onError.subscribe((error: any) => {
      this.isConnection = false
      console.log('Connection failed', error);
    });
    this.client?.onMessage.subscribe((packet: any) => {
      this.receiveNews = this.receiveNews.concat(packet.payload.toString())
      console.log(`Received message ${packet.payload.toString()} from topic ${packet.topic}`)
    })
  }

  // 订阅主题
  doSubscribe() {
    const { topic, qos } = this.subscription
    this.curSubscription = this.client?.observe(topic, { qos } as IClientSubscribeOptions).subscribe((message: IMqttMessage) => {
      this.subscribeSuccess = true
      console.log('Subscribe to topics res', message.payload.toString())
    })
  }
  // 取消订阅
  doUnSubscribe() {
    this.curSubscription?.unsubscribe()
    this.subscribeSuccess = false
  }
  // 发送消息
  doPublish() {
    const { topic, qos, payload } = this.publish
    console.log(this.publish)
    this.client?.unsafePublish(topic, payload, { qos } as IPublishOptions)
  }
  // 断开连接
  destroyConnection() {
    try {
      this.client?.disconnect(true)
      this.isConnection = false
      console.log('Successfully disconnected!')
    } catch (error: any) {
      console.log('Disconnect failed', error.toString())
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for chat
   */
  get chat$(): Observable<Chat> {
    return this._chat.asObservable();
  }

  /**
   * Getter for messages
   */
  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  /**
   * Setter for presence
   */

  set presence(status) {
    if (this._convergencePresence != null) {
      this._convergencePresence.setState("status", status);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Initialisation de la convergence
   */
  init() {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;
    });

    /*if (this._convergenceDomain == null) {
      console.log("-- Convergence init --");

      this.initNotification();

      var url = "https://realtimedata.eclipse360.fr/realtime/neomabs/codingschool";
      Convergence.connectWithJwt(url, this._authService.convergenceToken)
        .then((domain) => {
          console.log("-- Convergence Connected --");
          this._convergenceDomain = domain;
          this._convergencePresence = domain.presence();
          this._convergenceChat = domain.chat();
          this._convergenceActivity = domain.activities();
        })
        .catch((error) => {
          this._convergenceDomain = null;
          this._convergencePresence = null;
          this._convergenceChat = null;

          this._httpClient.post(environment.apiUrl + "user/convergence-token", this._authService.httpOptions).subscribe((response: any) => {
            // Store the access token in the local storage
            this._authService.convergenceToken = response;

            this.init();
          });
          console.log(error);
        });
    }*/
  }

  /**
   * Initialisation des notifications
   */
  async initNotification() {

  }

  /**
   * Destuction de la Convergence
   */
  destroy() {
    // Set the convergence to null
    this._convergenceDomain = null;
    this._convergencePresence = null;
    this._convergenceChat = null;
  }

  /**
   * Initialisation de chat
   */
  openChat(chatId) {
    this._convergenceChat
      .create({
        id: chatId,
        type: "room",
        membership: "public",
        ignoreExistsError: true,
      })
      .then((_chatId) => {
        return this._convergenceChat.join(_chatId);
      })
      .then((room) => {
        this._chatCurrent.room = room;

        this._chatCurrent.room
          .getHistory({
            limit: 25,
            // only return events of type "message"
            eventFilter: ["message"],
          })
          .then((response) => {
            let m = [];
            response.data.forEach((event) => {
              let userMsgId = event.user.username.substr(4);
              let displayName: String = "Me";
              let isMine: boolean = true;

              if (userMsgId != this.user.id) {
                displayName = event.user.displayName;
                isMine = false;
              }

              m.unshift({
                id: event.eventNumber,
                chatId: event.chatId,
                contactId: event.user.username.substr(4),
                displayName: displayName,
                isMine: isMine,
                value: this.htmlspecialchars_decode(event.message),
                createdAt: event.timestamp,
              });
            });

            this._chatCurrent.messages = m;

            // Update the messages
            this._messages.next(m);
          });

        this._chatCurrent.room.on("message", (event) => {
          let userMsgId = event.user.username.substr(4);
          let displayName: String = "Me";
          let isMine: boolean = true;

          if (userMsgId != this.user.id) {
            displayName = event.user.displayName;
            isMine = false;
          }

          this._chatCurrent.messages.push({
            id: event.eventNumber,
            chatId: event.chatId,
            contactId: event.user.username.substr(4),
            displayName: displayName,
            isMine: isMine,
            value: this.htmlspecialchars_decode(event.message),
            createdAt: event.timestamp,
          });

          this._messages.next(this._chatCurrent.messages);
        });
      });
  }

  htmlspecialchars_decode(str) {
    if (str != undefined) {
      var map = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": "\"",
        "&#39;": "'"
      };
      return str.replace(/(&amp;|&lt;|&gt;|&quot;|&#39;)/g, function (m) { return map[m]; });
    } else {
      return "";
    }
  }

  /**
   * Envoi d'un message sur le chat
   */
  sendToChat(message: string, directChat: boolean, toUserId: string = null) {
    if (this._chatCurrent.room != null) {
      if (directChat) {
        const hasToUser = this._chatCurrent.room.info().members.some((item) => item.user.username === "user" + toUserId);

        console.log(this._chatCurrent.room.info());

        if (!hasToUser) {
          console.log("-- NOTIFY USER -- ", toUserId);

          const params = {
            to_id: toUserId,
            msg: message,
            chat_id: this._chatCurrent.room.info().chatId
          };


          const opts = Object.assign(this._authService.httpOptions, params);

          this._httpClient.post(environment.apiUrl + "chat/notify-user", opts).subscribe((response: any) => {
          });
        }
      }

      this._chatCurrent.room.send(message, false);
    }
  }

  /**
   * Fermeture de session de chat
   */
  closeChat() {
    if (this._chatCurrent.room != null) {
      this._chatCurrent.room.removeAllListeners();

      // Leave the room.
      this._chatCurrent.room.leave().then(() => {
        console.log("Room leave");
        this._chatCurrent.room = null;
      });
    }
  }

  /*
     * Initialize Activity
     *
     */
  initActivity(activityType, activityId) {
    const options = {
      autoCreate: {
        ephemeral: true,
        worldPermissions: ["join", "view_state", "set_state"]
      }
    };

    this._convergenceActivity.join(activityType, activityId + '-user-' + this.user.id, options)
      .then((activity) => {
        console.log("activity joined");
      });
  }
}
