// -----------------------------------------------------------------------------------------------------
// @ AUTH MQTT
//
// Methods are derivations of the Convergence.io
// https://emqx.io
// -----------------------------------------------------------------------------------------------------

declare var require: any;

import { Injectable, OnDestroy, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, BehaviorSubject, filter, map, Observable, of, switchMap, take, takeUntil, tap, throwError, Subscription } from "rxjs";
import { AuthService } from "app/core/auth/auth.service";
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { environment } from "environments/environment";
import { Chat, Message } from "app/modules/admin/apps/chat/chat.types";

import {
  IMqttMessage,
  IMqttServiceOptions,
  MqttService,
  IPublishOptions,
} from 'ngx-mqtt';
import { IClientSubscribeOptions } from 'mqtt-browser';


//import { createClient } from 'redis';

@Injectable()
export class AuthMqtt implements OnDestroy {
  private _convergenceDomain: any = null;
  private _convergencePresence: any = null;
  private _convergenceChat: any = null;
  private _convergenceActivity: any = null;

  private _chatCurrent = {
    room: null,
    messages: null,
  };

  private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
  private _messages: BehaviorSubject<Message[]> = new BehaviorSubject(null);

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private user: User;

  private _subscription: any = [];

  public _chatEvent: EventEmitter<any> = new EventEmitter<any>();
  public _shareCodeEvent: EventEmitter<any> = new EventEmitter<any>();


  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _authService: AuthService, private _userService: UserService, private _mqttService: MqttService) {
    this.client = this._mqttService;
  }

  private curSubscription: Subscription | undefined;

  subscription = {
    topic: 'topic/mqttx',
    qos: 0,
  };
  publish = {
    topic: 'topic/browser',
    qos: 0,
    payload: '{ "msg": "Hello, I am browser." }',
  };
  receiveNews = '';
  qosList = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
  ];
  client: MqttService | undefined;
  isConnection = false;
  subscribeSuccess = false;


  // 取消订阅
  doUnSubscribe() {
    this.curSubscription?.unsubscribe()
    this.subscribeSuccess = false
  }
  // 发送消息
  doPublish() {
    const { topic, qos, payload } = this.publish
    console.log(this.publish)
    this.client?.unsafePublish(topic, payload.toString(), { qos } as IPublishOptions)
  }
  // 断开连接
  destroyConnection() {
    try {
      this.client?.disconnect(true)
      this.isConnection = false
      console.log('Successfully disconnected!')
    } catch (error: any) {
      console.log('Disconnect failed', error.toString())
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for chat
   */
  get chat$(): Observable<Chat> {
    return this._chat.asObservable();
  }

  /**
   * Getter for messages
   */
  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  /**
   * Setter for presence
   */

  set presence(status) {
    if (this._convergencePresence != null) {
      this._convergencePresence.setState("status", status);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Initialisation de la convergence
   */
  init() {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = user;

      const connection = {
        hostname: 'mqtt.coding-school.fr',
        path: '/mqtt',
        protocol: 'wss',
        clean: true,
        connectTimeout: 4000,
        reconnectPeriod: 4000,
        clientId: 'user-' + this.user.id,
        username: this.user.email,
        password: this._authService.accessToken
      }

      try {
        if (!this.isConnection) {
          this.client?.connect(connection as IMqttServiceOptions)
        }
      } catch (error) {
        console.log('mqtt.connect error', error);

      }

      this.client?.onConnect.subscribe(() => {
        this.isConnection = true
        this.subscribeTopic("user/" + this.user.id, 0);
        //this.doSubscribe();
        //this.doPublish();
      });

      this.client?.onError.subscribe((error: any) => {
        this.isConnection = false
        console.log('Connection failed', error);
      });

      this.client?.onMessage.subscribe((packet: any) => {

        const jsonString = Buffer.from(packet.payload).toString('utf8')
        const parsedData = JSON.parse(jsonString)

        if (parsedData.type == "chat") {
          this._chatEvent.emit(parsedData);
        } else if(parsedData.type == "shareCode") {
          this._shareCodeEvent.emit(parsedData);
        }

        //console.log(`Received message from topic ${packet.topic}`)
      })

    });

  }

  /**
   * Initialisation des notifications
   */
  async initNotification() {

  }

  /**
   * Destuction de la Convergence
   */
  destroy() {
    // Set the convergence to null
    this._convergenceDomain = null;
    this._convergencePresence = null;
    this._convergenceChat = null;
  }

  /**
   * Inscription à un topic
   * 
   * @param topic 
   * @param qos 
   */
  subscribeTopic(topic, qos) {
    //const { topic, qos } = this.subscription
    console.log(topic);

    let _curSubscription = this.client?.observe(topic, { qos } as IClientSubscribeOptions).subscribe();

    this._subscription.push({ 'topic': topic, 'sub': _curSubscription });
  }

  /**
   * Desinscription à un topic
   * 
   * @param topic 
   */
  unsubscribeTopic(topic) {
    const index = this._subscription.findIndex((sub) => sub.topic === topic);
    this._subscription[index].sub?.unsubscribe();
  }

  /**
   * Envoi de notification sur un topic
   * 
   * @param topic 
   * @param payload 
   * @param qos 
   */
  publishToTopic(topic, payload, qos, retain = false) {
    //console.log(topic, payload, qos);
    this.client?.unsafePublish(topic.toString(), JSON.stringify(payload), { qos, retain } as IPublishOptions);
  }

  /**
   * Initialisation de chat
   */
  /*openChat(chatId) {
    this._convergenceChat
      .create({
        id: chatId,
        type: "room",
        membership: "public",
        ignoreExistsError: true,
      })
      .then((_chatId) => {
        return this._convergenceChat.join(_chatId);
      })
      .then((room) => {
        this._chatCurrent.room = room;
 
        this._chatCurrent.room
          .getHistory({
            limit: 25,
            // only return events of type "message"
            eventFilter: ["message"],
          })
          .then((response) => {
            let m = [];
            response.data.forEach((event) => {
              let userMsgId = event.user.username.substr(4);
              let displayName: String = "Me";
              let isMine: boolean = true;
 
              if (userMsgId != this.user.id) {
                displayName = event.user.displayName;
                isMine = false;
              }
 
              m.unshift({
                id: event.eventNumber,
                chatId: event.chatId,
                contactId: event.user.username.substr(4),
                displayName: displayName,
                isMine: isMine,
                value: this.htmlspecialchars_decode(event.message),
                createdAt: event.timestamp,
              });
            });
 
            this._chatCurrent.messages = m;
 
            // Update the messages
            this._messages.next(m);
          });
 
        this._chatCurrent.room.on("message", (event) => {
          let userMsgId = event.user.username.substr(4);
          let displayName: String = "Me";
          let isMine: boolean = true;
 
          if (userMsgId != this.user.id) {
            displayName = event.user.displayName;
            isMine = false;
          }
 
          this._chatCurrent.messages.push({
            id: event.eventNumber,
            chatId: event.chatId,
            contactId: event.user.username.substr(4),
            displayName: displayName,
            isMine: isMine,
            value: this.htmlspecialchars_decode(event.message),
            createdAt: event.timestamp,
          });
 
          this._messages.next(this._chatCurrent.messages);
        });
      });
  }
 
  htmlspecialchars_decode(str) {
    if (str != undefined) {
      var map = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": "\"",
        "&#39;": "'"
      };
      return str.replace(/(&amp;|&lt;|&gt;|&quot;|&#39;)/g, function (m) { return map[m]; });
    } else {
      return "";
    }
  }*/

  /**
   * Envoi d'un message sur le chat
   */
  /*sendToChat(message: string, directChat: boolean, toUserId: string = null) {
    if (this._chatCurrent.room != null) {
      if (directChat) {
        const hasToUser = this._chatCurrent.room.info().members.some((item) => item.user.username === "user" + toUserId);
 
        console.log(this._chatCurrent.room.info());
 
        if (!hasToUser) {
          console.log("-- NOTIFY USER -- ", toUserId);
 
          const params = {
            to_id: toUserId,
            msg: message,
            chat_id: this._chatCurrent.room.info().chatId
          };
 
 
          const opts = Object.assign(this._authService.httpOptions, params);
 
          this._httpClient.post(environment.apiUrl + "chat/notify-user", opts).subscribe((response: any) => {
          });
        }
      }
 
      this._chatCurrent.room.send(message, false);
    }
  }*/

  /**
   * Fermeture de session de chat
   */
  /*closeChat() {
    if (this._chatCurrent.room != null) {
      this._chatCurrent.room.removeAllListeners();
 
      // Leave the room.
      this._chatCurrent.room.leave().then(() => {
        console.log("Room leave");
        this._chatCurrent.room = null;
      });
    }
  }*/

  /*
     * Initialize Activity
     *
     */
  /*initActivity(activityType, activityId) {
    const options = {
      autoCreate: {
        ephemeral: true,
        worldPermissions: ["join", "view_state", "set_state"]
      }
    };
 
    this._convergenceActivity.join(activityType, activityId + '-user-' + this.user.id, options)
      .then((activity) => {
        console.log("activity joined");
      });
  }*/
}
