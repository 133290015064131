import { Component, ElementRef, HostBinding, HostListener, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { ScrollStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import { Subject, takeUntil, take } from "rxjs";
import { AvailableLangs, TranslocoService } from "@ngneat/transloco";
import { FuseNavigationService, FuseVerticalNavigationComponent } from "@fuse/components/navigation";
import { Chat, Message } from "app/modules/admin/apps/chat/chat.types";
import { ChatService } from "app/modules/admin/apps/chat/chat.service";
import { AuthConvergence } from "app/core/auth/auth.convergence";
import { AuthMqtt } from "app/core/auth/auth.mqtt";
import { MarkdownService, MarkedRenderer } from "ngx-markdown";
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { FormBuilder, FormGroup, NgForm, Validators, FormControl, FormArray } from "@angular/forms";

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: "jitsi-viewer",
  templateUrl: "./jitsi-viewer.component.html",
  styleUrls: ["./jitsi-viewer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  exportAs: "jitsiViewer",
})
export class JitsiViewerComponent implements OnInit, OnDestroy {
  @ViewChild("messageInput") messageInput: ElementRef;

  messageInputControl = new FormControl();
  isTyping: boolean = false;
  //user: User;
  chat: Chat;
  messages: Message[];
  private currentTopic = "";
  notificationMsg = 0;
  availableLangs: AvailableLangs;
  activeLang: string;
  flagCodes: any;
  opened: boolean = false;
  sessionStarted: boolean = false;
  videoStarted: boolean = false;
  micStarted: boolean = false;

  domain: string = "jitsi.eclipse360.fr"; // For self hosted use your domain
  //domain: string = "meet.jit.si"; // For self hosted use your domain
  room: any;
  options: any;
  api: any;
  user: any;

  // For Custom Controls
  isAudioMuted = false;
  isVideoMuted = false;

  private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
  private _overlay: HTMLElement;
  private _jitsi: HTMLElement;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(private _elementRef: ElementRef, private _renderer2: Renderer2, private _ngZone: NgZone, private _scrollStrategyOptions: ScrollStrategyOptions, private _fuseNavigationService: FuseNavigationService, private _translocoService: TranslocoService, private _userService: UserService, private _navigationService: NavigationService, private _authMqtt: AuthMqtt, private _chatService: ChatService, private _changeDetectorRef: ChangeDetectorRef) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Decorated methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding("class") get classList(): any {
    return {
      "jitsi-viewer-opened": this.opened,
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._navigationService.inJitsi = false;

    // Event
    this._authMqtt._chatEvent.subscribe((event) => {
      if (event.type == "chat") {
         console.log(event);
        if (event.action == "typing" && event.from_id != this.user.id) {
          this.isTyping = true;
          // Mark for check
          this._changeDetectorRef.markForCheck();
          setTimeout(() => {
            this.isTyping = false;
            // Mark for check
            this._changeDetectorRef.markForCheck();
          }, 10000);
        } else if (event.action == "message" && event.from_id != this.user.id) {
            if(!this.opened) {
                this.notificationMsg++;
            }
          this.isTyping = false;
          this.messages.push({
            "isMine": false,
            "value": event.msg,
            "displayName": event.displayName,
            "createdAt": event.createdAt,
          });
          // Mark for check
          this._changeDetectorRef.markForCheck();
        } else if (event.action == "message" && event.from_id == this.user.id) {
            this.messages.push({
              "isMine": true,
              "value": event.msg,
              "displayName": event.displayName,
              "createdAt": event.createdAt,
            });
            // Mark for check
            this._changeDetectorRef.markForCheck();
          }
      }
    });

    // Chat
    this._chatService.chat$.pipe(takeUntil(this._unsubscribeAll)).subscribe((chat: Chat) => {
      this.chat = chat;

      this.messages = chat.messages;

      if (this.currentTopic != "chat/" + chat.id && this.currentTopic != "") {
        this._authMqtt.unsubscribeTopic(this.currentTopic);
      } else {
        this.currentTopic = "chat/" + chat.id;
        this._authMqtt.subscribeTopic(this.currentTopic, 0);
      }

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });

    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this.user = {
        name: user.first_name + " " + user.name,
        avatar: user.profile_photo_url,
        id : user.id
      };
    });

    // Subscribe to bar title update
    this._navigationService.roomJitsi$.pipe(takeUntil(this._unsubscribeAll)).subscribe((room: string) => {
      this.room = room;
      if (room != null) {
        this._chatService.getChatByCourse(room).subscribe();
      }
    });

    this._navigationService.inJitsi$.pipe(takeUntil(this._unsubscribeAll)).subscribe((inJitsi: boolean) => {
      if (!inJitsi && this._jitsi) {
        // Remove the backdrop
        this._jitsi.parentNode.removeChild(this._jitsi);
        this._jitsi = null;
        this.sessionStarted = false;
        this._toggleOpened(false);
        this._navigationService.isJitsi = false;
        this._navigationService.inJitsi = false;
        this._navigationService.roomJitsi = null;
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  startSession() {
    if (this.room != null) {
      // Create the jitsi element
      this._jitsi = this._renderer2.createElement("div");

      // Add a id to the jitsi element

      this._renderer2.setProperty(this._jitsi, "id", "jitsi-iframe");

      // Append the created div to the body element
      this._renderer2.appendChild(document.querySelector("#jitsi-contain"), this._jitsi);

      this.sessionStarted = true;

      this.options = {
        roomName: this.room,
        configOverwrite: {
          prejoinPageEnabled: false,
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          toolbarButtons: ["fullscreen", "desktop"],
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: toolbar,
          SHOW_JITSI_WATERMARK: false,
        },
        parentNode: document.querySelector("#jitsi-iframe"),
        userInfo: {
          displayName: this.user.name,
        },
      };

      this.api = new JitsiMeetExternalAPI(this.domain, this.options);

      //this.api.executeCommand('avatarUrl', this.user.avatar);

      // Event handlers
      this.api.addEventListeners({
        readyToClose: this.handleClose,
        participantLeft: this.handleParticipantLeft,
        participantJoined: this.handleParticipantJoined,
        videoConferenceJoined: this.handleVideoConferenceJoined,
        audioMuteStatusChanged: this.handleMuteStatus,
        videoMuteStatusChanged: this.handleVideoStatus,
      });

      this._navigationService.inJitsi = true;
      /*this.api.executeCommand('overwriteConfig', {
                toolbarButtons: []
            });*/
    }
  }

  executeCommand(command: string) {
    this.api.executeCommand(command);
    if (command == "hangup") {
      if (this._jitsi) {
        // Remove the backdrop
        this._jitsi.parentNode.removeChild(this._jitsi);
        this._jitsi = null;
        this.sessionStarted = false;
        this._toggleOpened(false);
        this._navigationService.isJitsi = false;
        this._navigationService.inJitsi = false;
        //this._navigationService.roomJitsi = null;
      }
      return;
    }

    if (command == "toggleAudio") {
      this.micStarted = !this.micStarted;
    }

    if (command == "toggleVideo") {
      this.videoStarted = !this.videoStarted;
    }
  }

  handleClose = () => {
    console.log("handleClose");
  };

  handleParticipantLeft = async (participant) => {
    console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
  };

  handleParticipantJoined = async (participant) => {
    console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant) => {
    console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    const data = await this.getParticipants();
  };

  handleMuteStatus = (audio) => {
    console.log("handleMuteStatus", audio); // { muted: true }
    this.micStarted = audio.muted;
  };

  handleVideoStatus = (video) => {
    console.log("handleVideoStatus", video); // { muted: true }
    this.videoStarted = video.muted;
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  /**
   * Open the panel
   */
  open(reset_notif:boolean = false): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }

    if(reset_notif) {
        this.notificationMsg = 0;
    }

    // Open the panel
    this._toggleOpened(true);
  }

  /**
   * Close the panel
   */
  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }

    // Close the panel
    this._toggleOpened(false);
  }

  /**
   * Toggle the panel
   */
  toggle(): void {
    console.log(this.opened);

    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  /**
   * Send message to room
   */
  sendMessageCourse(): void {
    // Récupération de l'input
    let message = this.messageInput.nativeElement.value;

    if (!this.onlySpaces(message)) {
      // Update du chat dans la BDD
      this.chat.lastMessage = this.messageInput.nativeElement.value.substr(0, 30) + "...";
      this.chat.message = this.messageInput.nativeElement.value;
      this._chatService.updateChat(this.chat.id, this.chat).subscribe();

      let data = { "type": "chat", "action": "message", "from_id": this.user.id, "msg": this.chat.message, "displayName": this.user.name, "createdAt": Date.now() };
      console.log(data);
      //Envoi du message
      this._authMqtt.publishToTopic(this.currentTopic, data, 0);

      //this._authConvergence.sendToChat(this.htmlspecialchars(this.messageInput.nativeElement.value), true, this.chat.contactId);*/

      // Effacement de l'input
      this.messageInput.nativeElement.value = "";
    }
  }

  focusEventCourse(): void {
    //Envoi du message
    this._authMqtt.publishToTopic(this.currentTopic, { "type": "chat", "action": "typing", "from_id": this.user.id }, 0);
  }

  onlySpaces(str) {
    return /^\s*$/.test(str);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the backdrop
   *
   * @private
   */
  private _showOverlay(): void {
    // Try hiding the overlay in case there is one already opened
    this._hideOverlay();

    // Create the backdrop element
    this._overlay = this._renderer2.createElement("div");

    // Return if overlay couldn't be create for some reason
    if (!this._overlay) {
      return;
    }

    // Add a class to the backdrop element
    this._overlay.classList.add("jitsi-overlay");

    // Append the backdrop to the parent of the panel
    this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

    // Enable block scroll strategy
    this._scrollStrategy.enable();

    // Add an event listener to the overlay
    this._overlay.addEventListener("click", () => {
      this.close();
    });
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _hideOverlay(): void {
    if (!this._overlay) {
      return;
    }

    // If the backdrop still exists...
    if (this._overlay) {
      // Remove the backdrop
      this._overlay.parentNode.removeChild(this._overlay);
      this._overlay = null;
    }

    // Disable block scroll strategy
    this._scrollStrategy.disable();
  }

  /**
   * Open/close the panel
   *
   * @param open
   * @private
   */
  private _toggleOpened(open: boolean): void {
    // Set the opened
    this.opened = open;

    // If the panel opens, show the overlay
    if (open) {
      //this._showOverlay();
    }
    // Otherwise, hide the overlay
    else {
      //this._hideOverlay();
    }
  }
}
