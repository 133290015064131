// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://coding-school.fr/api/",
  firebase: {
    apiKey: "AIzaSyBTsH-EQr39Cn7dNLQAtiIPkAjHY07O5dw",
    authDomain: "codingsch-6a34b.firebaseapp.com",
    databaseURL: "https://codingsch-6a34b-default-rtdb.firebaseio.com",
    projectId: "codingsch-6a34b",
    storageBucket: "codingsch-6a34b.appspot.com",
    messagingSenderId: "1044883305306",
    appId: "1:1044883305306:web:b387dd7291e4b5d47bb963"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
