import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { AuthService } from "app/core/auth/auth.service";

import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';

//import Pusher from 'pusher-js';

//const Pusher = require("pusher");

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

    user: User;
    echo: any;
    pusher: any;
    messages: Notification[];
    notifications: Notification[];
    notificationsMenu: any = {
        dashboard: 0,
        chat: 0,
        helps: 0,
        corrects: 0,
        classrooms: 0
    };
    unreadCount: number = 0;
    unreadMessageCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private privateChannel: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _router: Router,
        private _viewContainerRef: ViewContainerRef,
        private _userService: UserService,
        private _authService: AuthService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit() {

        // init notification on the full layout
        if (!this._router.url.includes('compact')) {

            // Connect to a pusher service
            this.echo = new Echo({
                broadcaster: 'pusher',
                key: 'd8bea88ecf0c653f11c4',
                cluster: 'eu',
                authEndpoint: 'https://coding-school.fr/broadcasting/auth',
                auth: {
                    headers: {
                        Authorization: "Bearer " + this._authService.apiToken,
                        Accept: "application/json"
                    }
                }
            });

            // Subscribe to user changes
            await this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
                this.user = user;
            });

            // Subscribe to private channel
            this.echo.private(`App.Models.User.${this.user.id}`)
                .notification((e) => {
                    this._notificationsService.createhNotification(e);
                    //this._notificationsService.getCountMenu().subscribe();

                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                });

            if (this.user.roles.includes('admin')) {
                this.echo.channel('global-helps')
                    .listen('HelpGlobalEvent', (e) => {
                        console.log(e);
                        this._notificationsService.createhNotificationGlobal(e);

                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    })
                    .listen('CorrectGlobalEvent', (e) => {
                        console.log(e);
                        this._notificationsService.createhNotificationGlobal(e);

                        // Mark for check
                        this._changeDetectorRef.markForCheck();
                    });
            }

            // Subscribe to notification changes
            this._notificationsService.messages$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((messages: Notification[]) => {

                    // Load the notifications
                    this.messages = messages;

                    // Calculate the unread count
                    //this._calculateMessagesUnreadCount();

                    this._calculateUnreadMenuCount();

                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                });

            // Subscribe to notification changes
            this._notificationsService.notifications$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((notifications: Notification[]) => {

                    // Load the notifications
                    this.notifications = notifications;

                    // Calculate the unread count
                    this._calculateUnreadCount();

                    this._calculateUnreadMenuCount();
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                });

            this._notificationsService.getAll().subscribe();

        }


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Open the notifications panel
     */
    openMessagePanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._messagesPanel || !this._messagesOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateMessagesUnreadCount(): void {
        let count = 0;

        
        if (this.messages && this.messages.length) {
            count = this.messages.filter(message => !message.read).length;
        }

        this.unreadMessageCount = count;

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Dashboard item and update
        const messageItem = this._fuseNavigationService.getItem('apps.chat', navigation);
        if (messageItem) {
            if (this.unreadMessageCount > 0) {
                messageItem.badge.title = this.unreadMessageCount.toString();
                messageItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
            } else {
                messageItem.badge = {};

                console.log('message count', this.messages);
            }
        }

        navComponent.refresh();

    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.read).length;
        }

        this.unreadCount = count;
        
    }

    /**
     * Calculate the unread menu count
     *
     * @private
     */
    private _calculateUnreadMenuCount(): void {

        let count = 0;

        
        if (this.messages && this.messages.length) {
            count = this.messages.filter(message => !message.read).length;
        }

        this.unreadMessageCount = count;


        let countHelp = 0;

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        if (this.notifications && this.notifications.length) {
            this.notificationsMenu.dashboard = this.notifications.filter(notification => notification.from == "helper" && !notification.read).length;
            this.notificationsMenu.dashboard = + this.notifications.filter(notification => notification.from == "corrector" && !notification.read).length;
            this.notificationsMenu.helps = this.notifications.filter(notification => notification.from == "student" && notification.type == "HelperNotification" && !notification.read).length;
            this.notificationsMenu.corrects = this.notifications.filter(notification => notification.from == "student" && notification.type == "CorrectNotification" && !notification.read).length;
            this.notificationsMenu.classrooms = this.notifications.filter(notification => notification.from == "student" && notification.type == "CorrectNotificationClassroom" && !notification.read).length;

            countHelp = this.notifications.filter(notification => !notification.read).length;

            // Get the Dashboard item and update
            const dashboardItem = this._fuseNavigationService.getItem('dashboard', navigation);
            if (dashboardItem) {
                if (this.notificationsMenu.dashboard > 0) {
                    dashboardItem.badge.title = this.notificationsMenu.dashboard;
                    dashboardItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
                } else {
                    dashboardItem.badge = {};
                }
            }

            // Get the Dashboard item and update
            const helpsItem = this._fuseNavigationService.getItem('helps', navigation);
            if (helpsItem) {
                if (this.notificationsMenu.helps > 0) {
                    helpsItem.badge.title = this.notificationsMenu.helps;
                    helpsItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
                } else {
                    helpsItem.badge = {};
                }
            }

            // Get the Dashboard item and update
            const ClassroomItem = this._fuseNavigationService.getItem('admin.classrooms', navigation);
            if (ClassroomItem) {
                if (this.notificationsMenu.classrooms > 0) {
                    ClassroomItem.badge.title = this.notificationsMenu.classrooms;
                    ClassroomItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
                } else {
                    ClassroomItem.badge = {};
                }
            }

            // Get the Dashboard item and update
            const adminItem = this._fuseNavigationService.getItem('admin', navigation);
            if (adminItem) {
                if (this.notificationsMenu.corrects > 0) {
                    adminItem.badge.title = this.notificationsMenu.corrects;
                    adminItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
                } else {
                    adminItem.badge = {};
                }
            }

            // Get the Dashboard item and update
            const adminCorrectItem = this._fuseNavigationService.getItem('admin.exercice-correct', navigation);
            if (adminCorrectItem) {
                if (this.notificationsMenu.corrects > 0) {
                    adminCorrectItem.badge.title = this.notificationsMenu.corrects;
                    adminCorrectItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
                } else {
                    adminCorrectItem.badge = {};
                }
            }

        }

        if (this.messages && this.messages.length) {
            this.notificationsMenu.chat = this.messages.filter(message => message.from == "student" && !message.read).length;

            // Get the Dashboard item and update
            const chatItem = this._fuseNavigationService.getItem('apps.chat', navigation);
            if (chatItem) {
                if (this.notificationsMenu.chat > 0) {
                    chatItem.badge.title = this.notificationsMenu.chat;
                    chatItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
                } else {
                    chatItem.badge = {};
                }
            }
        }

        // Get the Dashboard item and update
        const messageItem = this._fuseNavigationService.getItem('apps.chat', navigation);
        if (messageItem) {
            if (this.unreadMessageCount > 0) {
                messageItem.badge.title = this.unreadMessageCount.toString();
                messageItem.badge.classes = "px-2 bg-pink-600 text-white rounded-full";
            } else {
                messageItem.badge = {};

                console.log('message count', this.messages);
            }
        }

        navComponent.refresh();

    }
}
