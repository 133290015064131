import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthConvergence } from 'app/core/auth/auth.convergence';
import { AuthMqtt } from 'app/core/auth/auth.mqtt';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseConfigService } from '@fuse/services/config';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;
    
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _authConvergence: AuthConvergence,
        private _authMqtt: AuthMqtt,
        private _authService: AuthService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseConfigService: FuseConfigService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                //console.log(user);
                this._authConvergence.init();
                this._authMqtt.init();

                this.user = user;

                this.updateNavigation();

                this.updateTheme();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();

        this._authConvergence.presence = status;

    }

    /**
     * Update the user dark mode
     * 
     * @param dark_mode 
     * @returns 
     */
    updateUserTheme(dark_mode: number) {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            dark_mode
        }).subscribe();
    }

    /**
     * Settings
     */
    settings(): void {
        this._router.navigate(['/settings']);
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    /**
     * Update Navigation with Role
     */
    updateNavigation() {

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        let _nav:any = [];

        navigation.forEach((nav, index) => {

            let roles = nav.roles;

            if (roles != undefined && roles.length > 0) {
                var access = roles.some(i => this.user.roles.includes(i));
                if(access) {
                    _nav.push(nav);
                }
            } else {
                _nav.push(nav);
            }
        });

        navComponent.navigation = _nav;
        navComponent.refresh();
    }

    /**
     * Update Theme
     */
    updateTheme() {

        switch(this.user.dark_mode) {
            case 0:
                this._fuseConfigService.config = {scheme: 'auto'};
                break;
            case 1:
                this._fuseConfigService.config = {scheme: 'dark'};
                break;
            case 2:
                this._fuseConfigService.config = {scheme: 'light'};
                break;
        }

    }
    
}
