import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError } from "rxjs";
import { Chat, Contact, Profile } from "app/modules/admin/apps/chat/chat.types";
import { environment } from "environments/environment";
import { AuthService } from "app/core/auth/auth.service";
import { AuthConvergence } from "app/core/auth/auth.convergence";
import { AuthMqtt } from "app/core/auth/auth.mqtt";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private contactsArray :Contact[] = [];
  private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
  private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject(null);
  private _contact: BehaviorSubject<Contact> = new BehaviorSubject(null);
  private _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
  private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private _authConvergence: AuthConvergence,
    private _authMqtt: AuthMqtt
    ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for chat
   */
  get chat$(): Observable<Chat> {
    return this._chat.asObservable();
  }

  /**
   * Getter for chats
   */
  get chats$(): Observable<Chat[]> {
    return this._chats.asObservable();
  }

  /**
   * Getter for contact
   */
  get contact$(): Observable<Contact> {
    return this._contact.asObservable();
  }

  /**
   * Getter for contacts
   */
  get contacts$(): Observable<Contact[]> {
    return this._contacts.asObservable();
  }

  /**
   * Getter for profile
   */
  get profile$(): Observable<Profile> {
    return this._profile.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  createChat(id): Observable<any> {
    const params = {
      params: {
        to_id: id,
      },
    };
    const opts = Object.assign(this._authService.httpOptions, params);

    return this._httpClient.post<Chat>(environment.apiUrl + "chat/createChat/" + id, this._authService.httpOptions).pipe(
      map((chat) => {
        // Update the chat
        this._chat.next(chat);

        this.getChats().subscribe();

        this._authConvergence.openChat(chat.id);

        // Return the chat
        return chat;
      }),
      switchMap((chat) => {
        if (!chat) {
          return throwError("Could not found chat with id of " + id + "!");
        }

        return of(chat);
      })
    );
  }

  /**
   * Get chats
   */
  getChats(): Observable<any> {
    return this._httpClient.get<Chat[]>(environment.apiUrl + "chat/index", this._authService.httpOptions).pipe(
      tap((response: Chat[]) => {
        this._chats.next(response);
      })
    );
  }

  /**
   * Get contact
   *
   * @param id
   */
  getContact(id: string): Observable<any> {
    return this._httpClient.get<Contact>("api/apps/chat/contacts", { params: { id } }).pipe(
      tap((response: Contact) => {
        this._contact.next(response);
      })
    );
  }

  /**
   * Get contacts
   */
  getContacts(_offset = 0,_limit = 25): Observable<any> {

    const params = {
      params: {
        _offset,
        _limit
      },
    };
    const opts = Object.assign(this._authService.httpOptions, params);

    return this._httpClient.get<Contact[]>(environment.apiUrl + "chat/contact", opts).pipe(
      tap((response: Contact[]) => {

        for(let i = 0; i < response.length; i++){
          this.contactsArray.push(response[i]);
        }

        console.log(this.contactsArray);
        
        this._contacts.next(this.contactsArray);

        //this._contacts.next(response);
      })
    );
  }

  /**
     * Search contacts with given query
     *
     * @param q
     */
   searchContacts(q: string): Observable<Contact[]>
   {
       return this._httpClient.get<Contact[]>(environment.apiUrl +'contacts/search', {
           params: {q}
       }).pipe(
           tap((contacts) => {
               
            console.log(contacts);
            this.contactsArray = [];

               this._contacts.next(contacts);
           })
       );
   }
   
  /**
   * Get profile
   */
  getProfile(): Observable<any> {
    return this._httpClient.get<Profile>("api/apps/chat/profile").pipe(
      tap((response: Profile) => {
        this._profile.next(response);
      })
    );
  }

  /**
   * Get chat by course
   *
   * @param id
   */
  getChatByCourse(courseid:string): Observable<any> {
    const params = {
      params: {
        course_id: courseid,
      },
    };
    const opts = Object.assign(this._authService.httpOptions, params);

    return this._httpClient.get<Chat>(environment.apiUrl + "chat/course", opts).pipe(
      map((chat) => {
        // Update the chat
        this._chat.next(chat);

        //this._authMqtt.subscribeTopic('chat/user/' + chat.id + '/inbox', 0);

        //this._authConvergence.openChat(chat.id);

        // Return the chat
        return chat;
      }),
      switchMap((chat) => {
        if (!chat) {
          return throwError("Could not found chat with id of " + courseid + "!");
        }

        return of(chat);
      })
    );
  }

  /**
   * Get chat
   *
   * @param id
   */
  getChatById(id: string): Observable<any> {
    const params = {
      params: {
        chat_id: id,
      },
    };
    const opts = Object.assign(this._authService.httpOptions, params);

    return this._httpClient.get<Chat>(environment.apiUrl + "chat/chat", opts).pipe(
      map((chat) => {
        // Update the chat
        this._chat.next(chat);

        //this._authMqtt.subscribeTopic('chat/user/' + chat.id + '/inbox', 0);

        //this._authConvergence.openChat(chat.id);

        // Return the chat
        return chat;
      }),
      switchMap((chat) => {
        if (!chat) {
          return throwError("Could not found chat with id of " + id + "!");
        }

        return of(chat);
      })
    );
  }

  /**
   * Update chat
   *
   * @param id
   * @param chat
   */
  updateChat(id: string, chat: Chat): Observable<Chat> {
    const params = {
        params: {
          chat_id: id,
        },
      };
      const opts = Object.assign(this._authService.httpOptions, params);
      
    return this.chats$.pipe(
      take(1),
      switchMap((chats) =>
        this._httpClient
          .patch<Chat>(environment.apiUrl + "chat/chat", chat, this._authService.httpOptions)
          .pipe(
            map((updatedChat) => {
              // Find the index of the updated chat
              const index = chats.findIndex((item) => item.id === id);

              //if (!hasToUser) {
                console.log("-- NOTIFY USER -- ", chat.contactId);
      
                const params = {
                  to_id: chat.contactId,
                  msg: chat.message,
                  chat_id: id
                };
      
      
                const opts = Object.assign(this._authService.httpOptions, params);
      
                this._httpClient.post(environment.apiUrl + "chat/notify-user", opts).subscribe((response: any) => {
                });
              //}
            

              // Update the chat
              chats[index] = updatedChat;

              // Update the chats
              this._chats.next(chats);

              // Return the updated contact
              return updatedChat;
            }),
            switchMap((updatedChat) =>
              this.chat$.pipe(
                take(1),
                filter((item) => item && item.id === id),
                tap(() => {
                  // Update the chat if it's selected
                  this._chat.next(updatedChat);

                  // Return the updated chat
                  return updatedChat;
                })
              )
            )
          )
      )
    );
  }

  /**
   * Reset the selected chat
   */
  resetChat(): void {
    this._chat.next(null);
  }
}
