<div id="jisti-sidebar" class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow">
    <div
        class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{'-translate-x-full sm:-translate-x-96 lg:-translate-x-80 shadow': opened, 'translate-x-0': !opened}">

        

        <mat-tab-group class="h-full" animationDuration="0ms" (selectedTabChange)="open()">
            <mat-tab class="h-full">
                <ng-template mat-tab-label>
                    <span (click)="open(false)">
                        <mat-icon class="icon-size-6" [svgIcon]="'heroicons_outline:video-camera'"></mat-icon>
                    </span>
                </ng-template>
                
                <div class="h-full flex flex-col">
                    <!-- Header -->
                    <div class="jitsi-header flex flex-0 items-center justify-start">

                        <ng-container>
                            <div class="flex flex-auto items-center justify-center">
                                <div class="ml-4 text-lg font-medium text-secondary">Visio</div>
                                <button
                                    class="ml-auto mr-4"
                                    mat-icon-button (click)="close()">
                                    <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        
                    </div>

                    <div id="jitsi-contain" class="jitsi-content grow flex h-full justify-center">
                        <button class="fuse-mat-button-rounded m-auto" mat-flat-button [color]="'primary'" (click)="startSession()" *ngIf="!sessionStarted">Rejoindre le cours</button>
                    </div>
                
                    

                    <!-- footer -->
                    <div class="jitsi-footer flex flex-0 items-center justify-start cursor-pointer">
            
                        <!-- Toggle -->
                        <ng-container *ngIf="sessionStarted">
                            <div class="flex flex-auto" style="justify-content: space-around !important;">
                                <div class="flex flex-0 items-center justify-center p-3" *ngIf="!videoStarted" (click)="executeCommand('toggleVideo')">
                                    <mat-icon svgIcon="mat_solid:videocam"></mat-icon>
                                </div>
            
                                <div class="flex flex-0 items-center justify-center p-3" *ngIf="videoStarted" (click)="executeCommand('toggleVideo')">
                                    <mat-icon svgIcon="mat_solid:videocam_off"></mat-icon>
                                </div>
            
                                <div class="flex flex-0 items-center justify-center p-3" *ngIf="!micStarted" (click)="executeCommand('toggleAudio')">
                                    <mat-icon svgIcon="mat_solid:mic"></mat-icon>
                                </div>
            
                                <div class="flex flex-0 items-center justify-center p-3" *ngIf="micStarted" (click)="executeCommand('toggleAudio')">
                                    <mat-icon svgIcon="mat_solid:mic_off"></mat-icon>
                                </div>
            
                                <button class="flex flex-0 items-center justify-center fuse-mat-button-rounded" mat-flat-button [color]="'warn'" (click)="executeCommand('hangup')">
                                    <mat-icon class="icon-size-2" svgIcon="mat_solid:phone_disabled"></mat-icon>
                                </button>
                                
                            </div>
                        </ng-container>
                        
                    </div>

                </div>

              </mat-tab>
            
              <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="open(true)">
                        <mat-icon class="icon-size-6" [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                    </span>
                    <ng-container *ngIf="notificationMsg > 0">
                        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
                            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                                {{notificationMsg}}
                            </span>
                        </span>
                    </ng-container>
                </ng-template>
                <div class="h-full flex flex-col">
                    <!-- Header -->
                    <div class="jitsi-header flex flex-0 items-center justify-start">

                        <ng-container>
                            <div class="flex flex-auto items-center justify-center">
                                <div class="ml-4 text-lg font-medium text-secondary">Chat</div>
                                <button
                                    class="ml-auto mr-4"
                                    mat-icon-button (click)="close()">
                                    <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                                </button>
                            </div>
                        </ng-container>
                        
                    </div>

                    
                    <!-- Conversation -->
                    <div class="h-full flex overflow-y-auto flex-col-reverse">
                        <div class="flex flex-col flex-auto shrink p-6 bg-card dark:bg-transparent">
                            <ng-container *ngFor="let message of messages; let i = index; let first = first; let last = last; trackBy: trackByFn">
                                <!-- Start of the day -->
                                <ng-container *ngIf="first || (messages[i - 1].createdAt | date:'d') !== (message.createdAt | date:'d')">
                                    <div class="flex items-center justify-center my-3 -mx-6">
                                        <div class="flex-auto border-b"></div>
                                        <div class="flex-0 mx-4 text-sm font-medium leading-5 text-secondary">
                                            {{message.createdAt | date: 'dd MMM YYYY'}}
                                        </div>
                                        <div class="flex-auto border-b"></div>
                                    </div>
                                </ng-container>
                                <div
                                    class="flex flex-col"
                                    [ngClass]="{'items-end': message.isMine,
                                                'items-start': !message.isMine,
                                                'mt-0.5': i > 0 && messages[i - 1].isMine === message.isMine,
                                                'mt-3': i > 0 && messages[i - 1].isMine !== message.isMine}">
                                    <!-- Bubble -->
                                    <div
                                            class="my-0.5 text-sm font-medium text-secondary"
                                            [ngClass]="{'mr-3': message.isMine,
                                                        'ml-3': !message.isMine}">
                                            {{message.displayName}}
                                        </div>
                                    <div
                                        class="relative max-w-3/4 px-3 py-2 rounded-tl-xl rounded-tr-xl"
                                        [ngClass]="{'bg-blue-500 text-blue-50 rounded-bl-xl': message.isMine,
                                                    'bg-gray-500 text-gray-50 rounded-br-xl': !message.isMine}">
                                    
                                        <!-- Message -->
                                        <!--<div style="white-space: pre-line"
                                            class="min-w-4 leading-5"
                                            [innerHTML]="message.value">
                                        </div>-->

                                        <div class="prose prose-sm text-white">
                                            <markdown class="variable-binding" [data]="message.value"></markdown>
                                        </div>

                                    </div>
                                    <!-- Time -->
                                    <ng-container
                                        *ngIf="first
                                        || last
                                        || messages[i + 1].isMine !== message.isMine
                                        || messages[i + 1].createdAt !== message.createdAt">
                                        <div
                                            class="my-0.5 text-sm font-medium text-secondary"
                                            [ngClass]="{'mr-3': message.isMine,
                                                        'ml-3': !message.isMine}">
                                            {{message.createdAt | date:'HH:mm'}}
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>

                            <div class="flex flex-col items-start mt-3" *ngIf="isTyping">
                                <div class="relative max-w-3/4 px-3 py-2 rounded-tl-xl rounded-tr-xl rounded-br-xl bg-gray-500 text-gray-50">
                                    <div class="typing-indicator">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>  

                        </div>
                    </div>

                    <!-- Message field -->
                    <div class="flex items-end p-4 border-t bg-gray-50 dark:bg-transparent">
                        <!--<div class="flex items-center h-11 my-px">
                            <button mat-icon-button>
                                <mat-icon [svgIcon]="'heroicons_outline:emoji-happy'"></mat-icon>
                            </button>
                            <button
                                class="ml-0.5"
                                mat-icon-button>
                                <mat-icon [svgIcon]="'heroicons_outline:paper-clip'"></mat-icon>
                            </button>
                        </div>-->
                        <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded fuse-mat-bold w-full ml-4">
                            <textarea
                                class="min-h-5 my-0 resize-none"
                                style="margin: 11px 0 !important; padding: 0 !important;"
                                [rows]="1"
                                matInput
                                [formControl]="messageInputControl"
                                #messageInput (focus)='focusEventCourse()'></textarea>
                        </mat-form-field>
                        <div class="flex items-center h-11 my-px ml-4">
                            <button
                                mat-icon-button
                                (click)="sendMessageCourse()">
                                <mat-icon
                                    class="rotate-90"
                                    [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
                            </button>
                        </div>
                    </div>
                
    
                </div>  
              </mat-tab>
          </mat-tab-group>
        
    </div>
</div>