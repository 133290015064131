import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { TheiaService } from 'app/modules/admin/apps/theia/theia.service';

@Component({
    selector     : 'compact-layout',
    templateUrl  : './compact.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CompactLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    barTitle: string;
    showJitsi:boolean = false;
    showHelpCenter:boolean = false;
    showShareCode:boolean = false;
    isNewCode:boolean = false;
    isTyping:boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    compact = false;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _theiaService: TheiaService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        // init notification on the full layout
        if (this._router.url.includes('compact')) {
            this.compact = true;
        } else {
            this.compact = false;
        }

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to bar title update
        this._navigationService.barTitle$
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((title: string) => {
                this.barTitle = title;
            });

        // Subscribe to jitsi update
        this._navigationService.isJitsi$
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((show: boolean) => {
                this.showJitsi = show;
            });

        // Subscribe to Help Center update
        this._navigationService.isHelpCenter$
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((show: boolean) => {
                this.showHelpCenter = show;
            });

        // Subscribe to Share Code update
        this._navigationService.isShareCode$
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((show: boolean) => {
                this.showShareCode = show;
            });

        // Subscribe to Share Code update
        this._navigationService.isShareCode$
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((show: boolean) => {
                this.showShareCode = show;
            });
        
        // Subscribe to Share Code Typing
        this._navigationService.isShareCodeTyping$
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((show: boolean) => {
                if(show) {
                    if(!this.isTyping) {
                        this.isTyping = true;
                        setTimeout(() => {
                            this.isTyping = false; 
                            this.isNewCode = true;
                        }, 5000);
                    }
                } else {
                    this.isTyping = false; 
                    this.isNewCode = false;
                }
            });
        
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleHelpCenter() {
        this._theiaService.setToggleHelpCenter();
    }

    toggleShareCode() {
        this._theiaService.setToggleShareCode();
        if(this.isNewCode) {
            this.isNewCode = false;
        }
    }
}
