import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthConvergence } from 'app/core/auth/auth.convergence';
import { AuthMqtt } from 'app/core/auth/auth.mqtt';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';

import {
    IMqttMessage,
    MqttModule,
    IMqttServiceOptions
  } from 'ngx-mqtt';
  

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'mqtt.coding-school.fr',
    path: '/mqtt',
    protocol: 'wss'
};

@NgModule({
    imports: [
        HttpClientModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
    ],
    providers: [
        AuthService,
        AuthConvergence,
        AuthMqtt,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class AuthModule {
}
