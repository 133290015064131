import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { AuthService } from "app/core/auth/auth.service";
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private _messages: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    private _notificationsMenu: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _authService: AuthService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
    * Getter for notifications
    */
    get messages$(): Observable<Notification[]> {
        return this._messages.asObservable();
    }

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    /**
     * Getter for notifications menu
     */
    get notificationsMenu$(): Observable<any[]> {
        return this._notificationsMenu.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]> {
        return this._httpClient.get<Notification[]>(environment.apiUrl + 'user/all-notification', this._authService.httpOptions).pipe(
            tap((notifications) => {

                console.log(notifications);

                let notifs = [];
                let msgs = [];

                notifications.forEach((n) => {
                    if (n.type == "ChatNotification") {
                        msgs.push(n);
                    }

                    if (n.type == "HelperNotification") {
                        notifs.push(n);
                    }

                    if (n.type == "CorrectNotification") {
                        notifs.push(n);
                    }

                    if (n.type == "CorrectNotificationClassroom") {
                        notifs.push(n);
                    }

                })

                this._messages.next(msgs);
                this._notifications.next(notifs);
            })
        );
    }

    createhNotification(notification) {

        let notif = {};
        let msg = {};

        if (notification.type == "App\\Notifications\\ChatNotification") {
            msg = notification;

            this.messages$.pipe(take(1))
                .subscribe((messages: any[]) => {
                    messages.push(msg);
                    // Update the notifications
                    this._messages.next(messages);
                });

        } else {

            notif = notification;

            this.notifications$.pipe(take(1))
                .subscribe((notifications: any[]) => {
                    notifications.push(notif);
                    console.log(notifications);
                    // Update the notifications
                    this._notifications.next(notifications);
                });

        }

    };

    createhNotificationGlobal(notification) {

        let notif = {};
       

            notif = notification[0];

            this.notifications$.pipe(take(1))
                .subscribe((notifications: any[]) => {
                    notifications.push(notif);
                    console.log(notifications);
                    // Update the notifications
                    this._notifications.next(notifications);
                });

        
        
        console.log(notif);





    };

    /**
     * Get all notifications
     */
    getCountMenu(): Observable<any[]> {
        return this._httpClient.get<any[]>(environment.apiUrl + 'user/count-notification', this._authService.httpOptions).pipe(
            tap((notificationsMenu) => {
                this._notificationsMenu.next(notificationsMenu);
            })
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
                map((newNotification) => {

                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                })
            ))
        );
    }


    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {

        const params = {
            id,
            notification
        };

        const opts = Object.assign(this._authService.httpOptions, params);

        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>(environment.apiUrl + 'user/update-notification', opts).pipe(
                map((updatedNotification: Notification) => {

                    // Find the index of the updated notification
                    const indexNotif = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[indexNotif] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    this.messages$.pipe(take(1))
                        .subscribe((messages: any[]) => {
                            // Find the index of the deleted notification
                            const index = messages.findIndex(item => item.id === id);

                            // Delete the notification
                            messages[index] = updatedNotification;

                            // Update the notifications
                            this._messages.next(messages);
                        });

                    this.getCountMenu().subscribe();

                    // Return the updated notification
                    return updatedNotification;
                })
            ))
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        const params = {
            id
        };

        const opts = Object.assign(this._authService.httpOptions, params);

        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<boolean>(environment.apiUrl + 'user/delete-notification', opts).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    this.messages$.pipe(take(1))
                        .subscribe((messages: any[]) => {
                            // Find the index of the deleted notification
                            const index = messages.findIndex(item => item.id === id);

                            // Delete the notification
                            messages.splice(index, 1);

                            // Update the notifications
                            this._messages.next(messages);
                        });

                    //this.getCountMenu().subscribe();

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {

                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                })
            ))
        );
    }
}
