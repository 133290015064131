import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { AuthUtils } from '../auth/auth.utils';
import { environment } from "environments/environment";
import { TranslocoService } from '@ngneat/transloco';
import { FuseConfigService } from '@fuse/services/config';
import { AuthService } from 'app/core/auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _translocoService: TranslocoService, private _fuseConfigService: FuseConfigService, private _router: Router,) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    set accessUser(value: string) {
        // Store the value
        localStorage.setItem("user", value);
        //this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get accessUser(): string {
        return localStorage.getItem('user') ?? '';
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get apiToken(): string {
        return localStorage.getItem("accessTokenAPI") ?? "";
    }

    /**
     * Getter HTTP Header for request
     */
    get httpOptions() {
        return {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.apiToken,
            }),
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {

        /*
                let user = JSON.parse(this.accessUser);
        
                console.log(user);*/

        return this._httpClient
            .get<User>(
                environment.apiUrl + 'user'
            )
            .pipe(
                tap((user) => {
                    //this.accessUser = JSON.stringify(user);


                    this._translocoService.setActiveLang(user.default_language);

                    switch (user.dark_mode) {
                        case 0:
                            this._fuseConfigService.config = { scheme: 'auto' };
                            break;
                        case 1:
                            this._fuseConfigService.config = { scheme: 'dark' };
                            break;
                        case 2:
                            this._fuseConfigService.config = { scheme: 'light' };
                            break;
                    }

                    this._user.next(user);

                    if(user.change_password) {
                        if(this._router.url != '/change-password') {
                            console.log('reload to change password');
                        }
                        console.log(this._router.url);
                        //this._router.navigateByUrl('/sign-in');
                    }

                    if(user.convergence_token == null) {

                        // Sign out
                        //this._authService.signOut();
                        localStorage.clear();

                        // Reload the app
                        location.reload();
                        
                    }

                })
            );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {

        return this._httpClient.patch<User>(environment.apiUrl + "user/update", user, this.httpOptions).pipe(
            map((response) => {
                console.log(response);
                this._user.next(response);
            })
        );
    }

    /**
     * Update the profile img user
     *
     * @param user
     */
    updateProfileImg(img: File): Observable<any> {
        const formData = new FormData();
        formData.append('img', img, img.name);
          
        return this._httpClient.post<User>(environment.apiUrl + "user/update-profile-img",formData).pipe(
            map((response) => {
                console.log(response);
                this._user.next(response);
            })
        );
    }
}
