<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation *ngIf="!compact" class="dark bg-gray-900 print:hidden" [appearance]="'compact'" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigation.compact" [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20 mt-3 mb-4">
      <img class="w-20" src="assets/images/logo/coding-school-white.png" alt="Logo CodingSchool" />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0" *transloco="let t">
  <!-- Header -->
  <div *ngIf="!compact" class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <div class="flex items-center pl-2" [innerHtml]="barTitle"></div>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <button class="mr-5" mat-raised-button color="primary" *ngIf="showShareCode" (click)="toggleShareCode()">
        <ng-container *ngIf="isNewCode">
          <span class="absolute -top-2 -left-10 flex items-center justify-center h-3">
              <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-warn text-xs font-medium"></span>
          </span>
        </ng-container>
        <ng-container *ngIf="isTyping">
          <div class="typing">
            <div class="typing__dot"></div>
            <div class="typing__dot"></div>
            <div class="typing__dot"></div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isTyping">
          {{ t('header.share-code') }}
        </ng-container>
      </button>
      <button class="mr-5" mat-raised-button color="warn" *ngIf="showHelpCenter" (click)="toggleHelpCenter()">
        {{ t('header.help-center') }}
      </button>
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>

      <!--<messages></messages>-->
      <notifications></notifications>

      <user></user>

      <button [ngClass]="{ block: showJitsi, hidden: !showJitsi }" class="lg:hidden" mat-icon-button (click)="jitsiViewer.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:video-camera'"></mat-icon>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div class="relative flex flex-0 items-center justify-between w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
    <span class="font-medium text-secondary">Eclipse360 &copy; {{ currentYear }}</span>

<!--
    <div>
      <share-buttons
        theme="modern-dark"
        [include]="['copy', 'facebook', 'email', 'linkedin', 'twitter', 'whatsapp']"
        [showIcon]="true"
        [showText]="false"
        url="https://codingschool.neoma-bs.fr/"
        title="CodingSchool"
        description="Vous êtes étudiant, diplômé, professeur ou simple particulier et vous avez envie d’apprendre les rudiments du développement web ? Vous êtes au bon endroit! Des connaissances de base dans les langages web les plus utilisés vous permettront de collaborer bien plus facilement avec les développeurs et ingénieurs informatiques lors de vos missions professionnelles à venir."
        twitterAccount="rodrigokamada"
      >
      </share-buttons>
    </div>
  -->
  </div>
</div>

<!-- Jitsi -->
<jitsi-viewer [ngClass]="{ block: showJitsi, hidden: !showJitsi }" #jitsiViewer="jitsiViewer"></jitsi-viewer>
