import { Injectable } from '@angular/core';
import  {BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TheiaService {

    /**
   * Constructor
   */
  constructor() { }

	//private _helpCenterToggle = new Subject<any>(); 
	
	private _helpCenterToggle : BehaviorSubject<boolean | false> = new BehaviorSubject(null);

    private _shareCodeToggle : BehaviorSubject<boolean | false> = new BehaviorSubject(null);

	/**
     * Getter and Setter for HelpCenter
     */
    get toggleHelpCenter$(): Observable<boolean>
    {
        return this._helpCenterToggle.asObservable();
    }

	setToggleHelpCenter() {
		this._helpCenterToggle.next(true);
	}

    /**
     * Getter and Setter for ShareCode
     */
    get toggleShareCode$(): Observable<boolean>
    {
        return this._shareCodeToggle.asObservable();
    }

	setToggleShareCode() {
		this._shareCodeToggle.next(true);
	}
	
}