import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;
    user: User;
    
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _userService:UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to user changes
        this._userService.user$.subscribe((user: User) => {
                this.user = user;
            });


        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {

            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            'en': 'us',
            'fr': 'fr'
        };

        if(!navigator.language.includes('fr')) {
            this.setActiveLang('en');
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);

        // Return if user is not available
        if (!this.user) {
            return;
        }

        const default_language = lang;
        // Update the user
        this._userService.update({
            ...this.user,
            default_language
        }).subscribe();

    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Dashboard item and update
        const dashboardItem = this._fuseNavigationService.getItem('dashboard', navigation);
        if (dashboardItem) {
            this._translocoService.selectTranslate('menu.dashboard').pipe(take(1))
                .subscribe((translation) => {

                    // Set the title
                    dashboardItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.dashboard').pipe(take(1))
                .subscribe((translation) => {
                    dashboardItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Project item and update
        const projectItem = this._fuseNavigationService.getItem('projects', navigation);
        if (projectItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.projects').pipe(take(1))
                .subscribe((translation) => {
                    projectItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.projects').pipe(take(1))
                .subscribe((translation) => {
                    projectItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Get the My Projects item and update
            const myProjectItem = this._fuseNavigationService.getItem('projects.my-projects', navigation);
            if (myProjectItem) {
                // Set the title
                this._translocoService.selectTranslate('menu.my-projects').pipe(take(1))
                    .subscribe((translation) => {

                        myProjectItem.title = translation;

                        // Refresh the navigation component
                        navComponent.refresh();
                    });
            }

            // Get the Projects Team item and update
            const projectCoursesItem = this._fuseNavigationService.getItem('projects.projects-courses', navigation);
            if (projectCoursesItem) {
                // Set the title
                this._translocoService.selectTranslate('menu.projects-courses').pipe(take(1))
                    .subscribe((translation) => {

                        projectCoursesItem.title = translation;

                        // Refresh the navigation component
                        navComponent.refresh();
                    });
            }

            // Get the Projects Team item and update
            const projectTeamItem = this._fuseNavigationService.getItem('projects.projects-teams', navigation);
            if (projectTeamItem) {
                // Set the title
                this._translocoService.selectTranslate('menu.projects-teams').pipe(take(1))
                    .subscribe((translation) => {

                        projectTeamItem.title = translation;

                        // Refresh the navigation component
                        navComponent.refresh();
                    });
            }
        }

        // Get the Chat item and update
        const chatItem = this._fuseNavigationService.getItem('apps.chat', navigation);
        if (chatItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.chat').pipe(take(1))
                .subscribe((translation) => {

                    chatItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.chat').pipe(take(1))
                .subscribe((translation) => {
                    chatItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Tutorial item and update
        const tutorialItem = this._fuseNavigationService.getItem('tutorial', navigation);
        if (tutorialItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.tutorials').pipe(take(1))
                .subscribe((translation) => {

                    tutorialItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.tutorials').pipe(take(1))
                .subscribe((translation) => {
                    tutorialItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Evaluation item and update
        const evaluationItem = this._fuseNavigationService.getItem('evaluation', navigation);
        if (evaluationItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.evaluation').pipe(take(1))
                .subscribe((translation) => {

                    evaluationItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.evaluation').pipe(take(1))
                .subscribe((translation) => {
                    evaluationItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Exercices item and update
        const exercicesItem = this._fuseNavigationService.getItem('lessons', navigation);
        if (exercicesItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.exercices').pipe(take(1))
                .subscribe((translation) => {

                    exercicesItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.exercices').pipe(take(1))
                .subscribe((translation) => {
                    exercicesItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Get the Training item and update
            const trainingItem = this._fuseNavigationService.getItem('lessons.training', navigation);
            if (trainingItem) {
                // Set the title
                this._translocoService.selectTranslate('menu.exercices-training').pipe(take(1))
                    .subscribe((translation) => {

                        trainingItem.title = translation;

                        // Refresh the navigation component
                        navComponent.refresh();
                    });
            }

            // Get the Grading item and update
            const gradingItem = this._fuseNavigationService.getItem('lessons.grade', navigation);
            if (gradingItem) {
                // Set the title
                this._translocoService.selectTranslate('menu.exercices-grading').pipe(take(1))
                    .subscribe((translation) => {

                        gradingItem.title = translation;

                        // Refresh the navigation component
                        navComponent.refresh();
                    });
            }
        }

        // Get the MCQ item and update
        const mcqItem = this._fuseNavigationService.getItem('mcq', navigation);
        if (mcqItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.mcq').pipe(take(1))
                .subscribe((translation) => {

                    mcqItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.mcq').pipe(take(1))
                .subscribe((translation) => {
                    mcqItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

         // Get the Certifications item and update
         const certificationsItem = this._fuseNavigationService.getItem('certifications', navigation);
         if (certificationsItem) {
             // Set the title
             this._translocoService.selectTranslate('menu.certifications').pipe(take(1))
                 .subscribe((translation) => {
 
                    certificationsItem.title = translation;
 
                     // Refresh the navigation component
                     navComponent.refresh();
                 });
 
             // Set the tooltips
             this._translocoService.selectTranslate('menu.tooltips.certifications').pipe(take(1))
                 .subscribe((translation) => {
                    certificationsItem.tooltip = translation;
 
                     // Refresh the navigation component
                     navComponent.refresh();
                 });
         }

        // Get the Help item and update
        const helpItem = this._fuseNavigationService.getItem('helps', navigation);
        if (helpItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.helps').pipe(take(1))
                .subscribe((translation) => {

                    helpItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.helps').pipe(take(1))
                .subscribe((translation) => {
                    helpItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Classrooms item and update
        const classroomsItem = this._fuseNavigationService.getItem('admin.classrooms', navigation);
        if (classroomsItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.classrooms').pipe(take(1))
                .subscribe((translation) => {

                    classroomsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

            // Set the tooltips
            this._translocoService.selectTranslate('menu.tooltips.classrooms').pipe(take(1))
                .subscribe((translation) => {
                    classroomsItem.tooltip = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Classrooms item and update
        const adminUsersItem = this._fuseNavigationService.getItem('admin.users', navigation);
        if (adminUsersItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.users').pipe(take(1))
                .subscribe((translation) => {

                    adminUsersItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

        }

        // Get the Classrooms item and update
        const adminCorrectItem = this._fuseNavigationService.getItem('admin.exercice-correct', navigation);
        if (adminCorrectItem) {
            // Set the title
            this._translocoService.selectTranslate('menu.exercice-to-correct').pipe(take(1))
                .subscribe((translation) => {

                    adminCorrectItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });

        }

    }
}
