import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, takeUntil, catchError, Observable, of, switchMap, throwError, take, tap } from "rxjs";
import { AuthUtils } from "app/core/auth/auth.utils";
import { AuthConvergence } from "app/core/auth/auth.convergence";
import { AuthMqtt } from "app/core/auth/auth.mqtt";
import { UserService } from "app/core/user/user.service";
import { User } from "app/core/user/user.types";
import { environment } from "environments/environment";
import { Router } from '@angular/router';

declare var require: any;
const { Convergence, RealTimeString, RealTimeObject, StringSetValueEvent } = require("@convergence/convergence");

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  private _convergenceDomain: any = null;
  private _convergencePresence: any = null;
  private _convergenceChat:any = null;

  /**
   * Constructor
   */
  constructor(private _router: Router, private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem("accessToken", token);
  }

  get accessToken(): string {
    return localStorage.getItem("accessToken") ?? "";
  }

  /**
   * Setter & getter for Convergence token
   */
  set convergenceToken(token: string) {
    localStorage.setItem("convergenceToken", token);
  }

  get convergenceToken(): string {
    return localStorage.getItem("convergenceToken") ?? "";
  }

  /**
   * Setter & getter for JWT access token
   */
  set apiToken(token: string) {
    localStorage.setItem("accessTokenAPI", token);
  }

  get apiToken(): string {
    return localStorage.getItem("accessTokenAPI") ?? "";
  }

  /**
   * Getter HTTP Header for request
   */
  get httpOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.apiToken,
      }),
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post(environment.apiUrl + "auth/forgot-password", { email: email });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(value: any): Observable<any> {
    return this._httpClient.post(environment.apiUrl + "auth/reset-password", value);
  }

  changePassword(value: any): Observable<any> {
    const opts = Object.assign(this.httpOptions, value);
    return this._httpClient.post(environment.apiUrl + "auth/change-password", opts);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError("User is already logged in.");
    }

    return this._httpClient.post(environment.apiUrl + "auth/login", credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage

        this.accessToken = AuthUtils.generateJWTToken(response.accessToken);

        this.convergenceToken = response.convergenceToken;

        this.apiToken = response.accessToken;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response.user;

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post("api/auth/refresh-access-token", {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          //this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          //this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.clear();
    /*localStorage.removeItem("accessToken");
    localStorage.removeItem("convergenceToken");
    localStorage.removeItem("accessTokenAPI");*/

    // Set the authenticated flag to false
    this._authenticated = false;

    // Destroy the convergence 
    //AuthConvergence.destroy();
    
    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: { name: string; first_name: string, email: string; password: string;establishment: string; default_language:string }): Observable<any> {

    
    return this._httpClient.post(environment.apiUrl + "auth/register", user).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage

        if(response.status == "error") {
          return of(response);
        } else {
          this.accessToken = AuthUtils.generateJWTToken(response.accessToken);

          this.convergenceToken = response.convergenceToken;
  
          this.apiToken = response.accessToken;
  
          // Set the authenticated flag to true
          this._authenticated = true;
  
          // Store the user on the user service
          this._userService.user = response.user;
        }

        // Return a new observable with the response
        return of(response);
      })
    );

    //this.signIn(email, password);

    //return this._httpClient.post("api/auth/sign-up", user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post("api/auth/unlock-session", credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      //return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    
    
    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

}
