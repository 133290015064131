import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { JitsiViewerComponent } from 'app/layout/common/jitsi-viewer/jitsi-viewer.component';
import { SharedModule } from 'app/shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MarkdownModule } from 'ngx-markdown';
import { SecurityContext } from '@angular/core';

@NgModule({
    declarations: [
        JitsiViewerComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTabsModule,
        SharedModule,
        FuseDrawerModule,
        FuseScrollbarModule,
        MatFormFieldModule,
        MatInputModule,
        MarkdownModule.forRoot({
            sanitize: SecurityContext.NONE
          }),
    ],
    exports     : [
        JitsiViewerComponent
    ]
})
export class JitsiViewerModule
{
}
